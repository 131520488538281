import { useEffect } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';

// Utils
import SCOPES from '../src/gate/scopes';
import withSidebar from '../src/hocs/withSidebar';
import withAuth from '../src/hocs/withAuth';
import withKeyAccountContext from '../src/hocs/withKeyAccountContext';
import { isGranted } from '../src/gate/PermissionsGate';
import getCookieObject from '../src/utils/cookie';

// Hooks
import useMenuEntriesByRole from '../src/hooks/useMenuEntriesByRole';
import useRouter from '../src/hooks/useRouter';

// Components
const Catalog = dynamic(() => import('../components/template/Catalog/Catalog'));

import styles from '../styles/pages/index.module.scss';

function Home() {
  const router = useRouter();
  const user = getCookieObject('user');

  const menuEntries = useMenuEntriesByRole(user?.roles);

  useEffect(() => {
    if (!isGranted(user, [SCOPES.canViewClientHome])) {
      if (menuEntries[0]?.path) router.push(menuEntries[0].path);
    }
  }, [menuEntries]);

  return isGranted(user, [SCOPES.canViewClientHome]) ? (
    <div className={styles.container}>
      <Catalog />
    </div>
  ) : null;
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'fr', ['common', 'errors'])),
    },
  };
}

export default withAuth(withKeyAccountContext(withSidebar(Home, { overlay: true, cart: { showCart: false } })));
